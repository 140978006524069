import React, { Component } from 'react';
// import { Trans } from 'react-i18next';
import "../dashboard/Dashboard.css"

class Footer extends Component {
  render () {
    return (
      <footer className="footer" id='dashFooter'>
        <div className="container-fluid">
          <div className="d-sm-flex justify-content-md-center justify-content-sm-between py-2 w-100">
            <span className="text-center  text-sm-left d-block d-sm-inline-block">Copyright © <a href="https://rdxludo.in" target="_blank" rel="noopener noreferrer" className="text-white">rdxludo.in</a> 2024</span>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;